const en = {
	common: {
		copy: "Copy",
		copied: "Copied",
		ok: "OK!",
		cancel: "Cancel",
		back: "Back",
		metaOraPlus: "MetaOra Plus",
		continue: "Continue",
		no: "No",
		yes: "Yes",
		email: "Email",
		password: "Password",
		phoneNumber: "Phone Number",
		newPassword: "New Password",
		confirmNewPassword: "Confirm New Password",
		confirmPassword: "Confirm Password",
		fullname: "Full name",
		title: "Title",
		and: "and",
		or: "or",
		send: "Send",
		here: "here",
		login: "Login",
		signup: "Sign Up",
		submit: "Submit",
		amount: "{{currency}}{{amount}}",
		addImage: "Add Image",
		addNewNote: "Add New Note",
		editNote: "Edit Note",
		dictionary: "Dictionary",
		nextUnitAccess: "You can't access to the next units",
		of: "Of",
		premium: "Premium",
		free: "Free",
		all: "All",
	},
	commonErrors: {
		unknown: "Something went wrong!",
		tryAgain: "Something went wrong, Please try again later!",
		invalidEmail: "The Email is inavlid!",
	},
	signupScreen: {
		welcome: "Welcome to MetaOra!",
		email: "Sign up with Email",
		apple: "Sign up with Apple",
		google: "Sign up with Google",
		phone: "Sign up with Phone (SMS)",
		login: "Login",
		loginMsg: "Already got an account?",
	},
	signupForm: {
		submit: "Create an account",
		termsMsg: "By creating an account you agree to our",
		termOfService: "Terms of Service",
		privacy: "Privacy Policy",
		agree: "",
	},
	validation: {
		maxLength: "The maximum length is %{count}",
		minLength: "The minimum length is %{count}",
		max: "The maximum value coudn't be more than %{count}",
		min: "The minimum value coudn't be less than %{count}",
		required: "The %{field} is required",
		required2: "This field is required",
		email: "Please insert a valid email",
		string: "this field must be a string",
		password:
			"Password must have at least one lowercase letter, one uppercase letter, and one digit.",
		length: "The %{field} must be %{count} characters long",
		justNumber: "Please just use numbers",
		passwordMatch: "The two passwords that you entered do not match!",
		general: "There is a validation error",
		numberStepper: "This number must be between %{min} and %{max}",
	},
	loginScreen: {
		pageTitle: "Sign in",
		registerMsg: "Are you not registered?",
		register: "Register",
		apple: "Sign In with Apple",
		google: "Sign In with Google",
		phone: "Sign in with Phone (SMS)",
		submit: "Login",
		email: "Sign In with Email",
	},
	loginForm: {
		submit: "Login",
		forgetMsg: "Forgotten your Password?",
		forgetLink: "Reset",
	},
	phoneScreen: {
		signup: {
			pageTitle: "Sign up with Phone",
		},
		enterPhoneNumber: "Please enter your phone number below:",
		createAccount: "Create An Account",
		submit: "Send Verification Code",
		signin: {
			pageTitle: "Sign in with Phone",
		},
	},
	verifyCodeScreen: {
		pageTitle: "Verification Code",
		title: "Please enter the verify code below:",
		subtitle:
			"You have just 2 minutes to verify. if you have not received the code, you can try again after 2 minutes.",
		sendAgain: "Send again",
		invalidVerifyCode: "The verification code is invalid!",
		invalidVerifyType: "The verification type is invalid!",
		verifyDirectAccess:
			"The verification process is not accessible directly!",
	},
	forgetPasswordScreen: {
		pageTitle: "Forgotten your Password?",
		title: "Don't worry",
		subtitle:
			"it happens. Please enter the address associated with your account.",
		submit: "Send",
		sentMsg:
			"An email has just been sent to you. you can use it to retrieve your password",
	},
	walkthroughScreen: {
		submit: "Let's go and enjoy!",
		slide1Title: "Beyond the barriers of learning",
		slide1Msg:
			"Welcome to the new world of MetaOra: the distinct route of learning languages that we map out specifically for you.",
		slide2Title: "Lesson on demand",
		slide2Msg:
			"Now, It’s your turn to choose the subject of your course. In MetaOra, you will learn vocabulary, phrases, pronunciations, and grammar patterns through several courses with various topics. Try it and find its efficiency.",
		slide3Title: "It’s gamified!",
		slide3Msg:
			"The smart competitive ones, or those who look for the fun side of everything, will have a great learning experience here. Every step you take, any progress you make, MetaOra has a reward to encourage your achievement.",
		slide4Title: "Anybody with any knowledge",
		slide4Msg:
			"In MetaOra, everyone can boost their knowledge, from an absolute beginner to a fluent advanced learner.",
		slide5Title: "Learn faster, comprehend deeper, and never forget",
		slide5Msg:
			"MetaOra’s Artificial Intelligence provides a different learning experience based on a specific cycle of review and practices that cause fast, deep, and never-to-be- forgotten learning.",
	},
	resetPassword: {
		pageTitle: "Reset your password",
		title: "Enter a new password",
		submit: "Reset Password",
		resetMsg: "Your password has been reset and you can login from",
	},
	selectAffiliateScreen: {
		pageTitle: "Who's your affiliate?",
		subheading:
			"Select your affiliate. It will not be available later. So, please just search by their title or code and choose them now!",
		selectAffiliate: "Select an affiliate",
		submit: "Submit",
		searchPlaceholder: "Search",
		confirm: "Confirm",
		cancel: "Cancel",
	},
	affiliateScreen: {
		heading: "Become an affiliate!",
		updateHeading: "Update the affiliate!",
		pageTitle: "Affiliate",
		subHeading:
			"Please enter a title. The users you would introduce, can search and see you with this name. you can use your brand, etc.",
		enterTitle: "Brand title, etc...",
		affiliateStatsHeader: "Affiliate Stats",
		affiliateTitle: "Affiliate Title",
		affiliateStatus: "Affiliate Status",
		totalEarnings: "Total Earnings",
		totalReferrals: "Total Referrals",
		totalInvitedUsers: "Total Invited Users",
		withdrawalAmount: "Withdrawal Amount",
		commission: "Commission",
		affiliateCode: "Affiliate Code",
		affiliateLink: "Affiliate Link",
		affiliateMenuHeader: "Affiliate Menu",
		bankInformation: "Bank Information",
		paymentRequests: "Payment Requests",
		withdrawalList: "Withdrawal List",
		pending: "Pending",
		inactive: "Inactive",
		active: "Active",
		referrals: "Referrals",
		invitedUsers: "Invited Users",
		balance: "Balance",
		noBody: "Nobody",
	},
	affiliateBankInfoScreen: {
		pageTitle: "Bank Information",
		heading: "Your Bank Information",
		subHeading: "Please provide your bank information",
		submit: "Submit",
		accountHolderLabel: "Account Holder",
		accountHolder: "Account Holder",
		bankNameLabel: "Bank Name",
		bankName: "Bank Name",
		IBANNumberLabel: "IBAN Number",
		IBANNumber: "IBAN Number",
		cardNumberLabel: "Card Number",
		cardNumber: "Card Number",
		successMsg: "Your Bank Information saved successfully!",
	},
	paymentRequestScreen: {
		pageTitle: "Payment Requests List",
		addBtn: "Add New request",
		infoMsg: "The minimum amount to request is %{amount}",
		emptyList: "There is no payment requests.",
		status: "Status",
		payDateReq: "Request Date",
		amount: "Request Amount",
		pending: "Pending",
		approved: "Approved",
		rejected: "Rejected",
	},
	subscriptionPlanScreen: {
		pageTitle: "Premium Plans",
		couponCodeLabel: "Coupon Code",
		couponCodeRequired: "Please enter a coupon code",
		couponCodeLength: "Coupon code length must be be equal 6 characters.",
		couponCodePlaceholder: "Enter Coupon Code",
		apply: "Apply",
		payment: "Pay",
		planPrice: "Plan Price",
		discount: "Discount",
		finalPrice: "Final Price",
		emptyList: "There is no subscription plans",
	},
	paymentRequestNewScreen: {
		pageTitle: "New Payment Request",
		heading: "Create a new payment request",
		subHeading: "Please consider the minimum amount and also your balance!",
		submit: "Submit",
		requestedAmountLabel: "Amount",
		requestedAmount: "Amount",
		successMsg: "Your request saved successfully!",
	},
	withdrawScreen: {
		pageTitle: "Withdraw List",
		emptyList: "There are no withdrawals.",
		paymentAmount: "Paid Amount",
	},
	ReferralsScreen: {
		pageTitle: "Referrals List",
		emptyList: "There are no Referrals.",
		yourBenefit: "Your Benefit",
		id: "ID",
		productPrice: "productPrice",
		commission: "commission",
	},
	invitedUsersScreen: {
		pageTitle: "Invited Users List",
		emptyList: "There are no users.",
		refBuyCount: "Buy count",
		id: "ID",
	},
	admin: {
		affiliate: {
			detailsModal: {
				title: "Title",
				code: "Code",
				commissionPercentage: "Commission Percentage",
				totalEarnings: "Total Earnings",
				totalWithdrawn: "Total Withdrawn",
				invitedUsersCount: "Invited Users Count",
				referralCount: "Referral Count",
				status: "Status",
				createdAt: "Created At",
				updatedAt: "Updated At",
				fullname: "Fullname",
				email: "Email",
				phoneNumber: "Phone Number",
				bankName: "Bank Name",
				IBANNumber: "IBAN Number",
				cardNumber: "Card Number",
				accountHolder: "Account Holder",
			},
		},
		paymentRequest: {
			detailsModal: {
				affiliateTitle: "Affiliate Title",
				userFullName: "Fullname",
				userId: "User ID",
				affiliateId: "Affiliate ID",
				requestedAmount: "Requested Amount",
				status: "Status",
				createdAt: "Created At",
				updatedAt: "Updated At",
				bankName: "Bank Name",
				IBANNumber: "IBAN Number",
				cardNumber: "Card Number",
				accountHolder: "Account Holder",
			},
		},
		affiliatePayment: {
			detailsModal: {
				affiliateTitle: "Affiliate Title",
				userFullName: "Fullname",
				userId: "User ID",
				affiliateId: "Affiliate ID",
				paymentAmount: "Payment Amount",
				paymentRequestId: "Payment Request Id",
				createdAt: "Created At",
				updatedAt: "Updated At",
				description: "Description",
			},
		},
		referrals: {
			detailsModal: {
				productPrice: "Product Price",
				commissionPercentage: "Commission Percentage",
				orderId: "Order Id",
				createdAt: "Created At",
				id: "ID",
				title: "Title",
				code: "Code",
				status: "Status",
				fullname: "Fullname",
				email: "Email",
				phoneNumber: "Phone Number",
			},
		},
		user: {
			detailsModal: {
				id: "ID",
				fullname: "Fullname",
				email: "Email",
				username: "Username",
				phoneNumber: "Phone Number",
				role: "Role",
				nativeLanguage: "Native Language",
				subscription: "Subscription",
				authenticationModel: "Authentication Model",
				createdAt: "Created At",
				updatedAt: "Updated At",
				currentStreak: "Current Streak",
				lastDailyGoalReached: "Last Daily Goal Reached At",
				dailyGoalType: "Daily Goal Type",
				dailyGoalAmount: "Daily Goal Amount",
				studyTimeReminder: "Study Time Reminder",
				hasUsedTrialVersion: "Has Used Trial Version",
				isAffiliate: "Is Affiliate",
				myAffiliateId: "Affiliate ID",
				isReferredUser: "Is Referred User",
				affiliate: "Referred By",
				verified: "Verified?",
				emailVerified: "Email Verified?",
				phoneVerified: "Phone Number Verified?",
				status: "Status",
			},
		},
		subscriptionPlans: {
			detailsModal: {
				id: "ID",
				name: "Name",
				title: "Title",
				subtitle: "Subtitle",
				amount: "Amount",
				duration: "Duration",
				unit: "Unit",
				description: "Description",
				image: "Image",
				createdAt: "CreatedAt",
				updatedAt: "UpdatedAt",
			},
		},
		coupon: {
			detailsModal: {
				id: "ID",
				title: "Title",
				code: "Code",
				discount: "Discount",
				startDate: "Start Date",
				expiryDate: "Expiry Date",
				maxUsageCount: "Max Usage Count",
				currentUsageCount: "Current Usage Count",
				status: "Status",
				type: "Type",
				createdAt: "Created At",
				updatedAt: "Updated At",
				fullname: "Fullname",
				email: "Email",
				phoneNumber: "Phone Number",
				user: "User",
				commissionPercentage: "Commission",
				name: "Name",
				amount: "Amount",
				duration: "Duration",
				unit: "Unit",
			},
		},
		transaction: {
			detailsModal: {
				id: "ID",
				amount: "Amount",
				payDate: "Pay Date",
				transactionCode: "Transaction Code",
				orderId: "Order Id",
				status: "Status",
				createdAt: "Created At",
				updatedAt: "Updated At",
				fullname: "Fullname",
				email: "Email",
				phoneNumber: "PhoneNumber",
				name: "Plan Name",
				title: "tPLan Title",
				duration: "Duration",
				unit: "Unit",
			},
		},
	},
	latestCourses: {
		header: "Courses",
	},
	home: {
		featuredCourses: "Featured Courses",
		lastStudy: "Last Studying lesson",
	},
	sideMenu: {
		dictionary: "Dictionary",
		helpCenter: "Help Center",
		reportBug: "Report Bug",
		sendFeedback: "Send Feedback",
		policy: "Privacy policy",
		terms: "General terms and conditions",
		logout: "Logout",
		logoutAll: "Logout of All Devices",
		slogan: "Beyond the barriers of learning",
		version: "Metaora, Version",
		goToAdmin: "Go to Admin",
		planning: "Planning",
		home: "Home",
		courses: "Courses",
		affiliate: "Affiliate",
		review: "Review",
		metaOraPlus: "Start MetaOra Plus Now!",
		metaOraPlusRenew: "Renew to MetaOra Plus Now!",
	},
	components: {
		heading: {
			viewAll: "View All",
		},
	},
	englishLevel: {
		A0: "Absolute Beginner",
		A1: "Starter",
		A2: "Elementary",
		B1: "Lower Intermediate",
		B2: "Upper Intermediate",
		C1: "Advanced",
		C2: "Proficient",
		C3: "Towards Native",
	},
	courses: {
		all: "All Courses",
		assigned: "Assigned Courses",
		yours: "Your Courses",
	},
	lesson: {
		watchEnjoy: "Watch & Enjoy!",
		videoStep: "Video",
		videoStepDesc: "First step, listening and reading",
		watchBtn: "Watch",
		vocabularyNotes: "Vocabulary Notes",
		vocabulary: "Vocabulary",
		vocabularyDesc: "Start activating your vocabulary",
		orderGame: "Order Game",
		playWithWords: "Play with words",
		speaking: "Speaking",
		speakingDesc: "Strengthen your speaking",
		write: "write",
		writeDesc: "Strengthen your writing",
		currUnit: "Current Unit → %{unit}",
		nextUnit: "Unlock Next Unit",
		IdontKnow: "I don't know",
		IKnow: "I know",
		vocabListStep: "Vocabulary List",
		vocabListStepDesc: "Review list of all vocabulary!",
		letsSee: "Let's see!",
	},
	note: {
		submitNote: "Submit Note",
		expressionOrWord: "Expression or Word",
		wordsSelectorMsg: "You need to choose at least one word",
		noteType: "Note Type",
		noteTypeMsg: "Please select a note type",
		definition: "Definition",
		emptyFieldMsg: "This field can't be empty",
		definitionPlaceholder: "Enter a memoriable definition",
		example: "Example",
		examplePlaceholder: "Enter an Example",
		noteImg: "Note Image",
		baseForm: "‌Base Form",
		baseFormPlaceholder: "Enter the epression's base form",
		newNote: "New Note",
		fromLib: "From library",
		searchNote: "Search Note",
		removeConfirmTitle: "Remove Note",
		removeConfirmMessage:
			"Are you sure to delete this Note from this sentence?",
	},
	profile: {
		deleteAccount: "Delete Account",
		logoutAll: "Logout of All Devices",
		logout: "Logout",
		policy: "Privacy policy",
		terms: "General terms and conditions",
		metaOraPlus: "Start MetaOra Plus Now!",
		metaOraPlusRenew: "Renew to MetaOra Plus Now!",
		changePassword: "Change Password",
		affilate: "Affiliate",
		learningLanguage: "Learning Language",
		nativeLanguage: "Native Language",
		username: "Username",
		profile: "Profile",
		pageTitle: "Your account",
		phoneNumber: "Phone Number",
		others: "Others",
		subscriptionPlanTitle: "Subscription Plan",
		subscriptionStart: "Plan Start Date",
		subscriptionEnd: "Plan End Date",
		subscriptionStatus: "Plan Status",
		subscriptionStatus_active: "Active",
		subscriptionStatus_expired: "Expired",
		role_subscriber: "Subscriber",
		role_author: "Author",
		role_admin: "Admin",
		subscription_free: "Free",
		subscription_premium: "Premium",
		settings: "Settings",
		textToSpeech: "Text To Speech",
		notifications: "Notifications",
	},
	review: {
		pageTitle: "Your Review Lessons",
		emptyListMsg: "There is no lesson for review.",
		pageSingleTitle: "Review",
	},
	quiz: {
		checkAnswer: "Check Answer",
		play: "Play",
		saveNext: "Save & Next",
		saveEnd: "Save & End",
		easily: "easily",
		withhesitation: "with hesitation",
		withDifficulty: "with difficulty",
		notTooHard: "not too hard",
		hard: "hard",
		reallyHard: "really hard",
		ICouldAnswer: "I could answer:",
		theQuestionWas: "The question was:",
	},
	orderGame: {
		pageTitle: "Order Game",
		next: "Next",
		end: "End",
		play: "Play",
		endUnit: "Save & Close",
	},
	speaking: {
		pageTitle: "Speak & Compare",
	},
	player: {
		hideSubtitle: "Subtitles are hidden",
	},
	vocabulary: {
		pageTitle: "Vocabulary",
	},
	writing: {
		pageTitle: "Writing",
	},
	achievement: {
		resultTitle: "Result & Achievement",
		unitDoneTitle: "The Unit is completed!",
		dailyGoal: "Daily goal reached!",
		next: "Next",
		retry: "Retry",
		redirectMsg: "Navigating to the result of unit...",
		failedMsg: "Oops, you failed!",
		successMsg: "Got %{count} bonus stars!",
	},
	mediaLesson: {
		watchAgain: "Watch Video Again!",
		goPractice: "Go to practices",
		congrat: "Congratulations!",
		inProgress: "In progress!",
		complete: "Complete",
		learnedWords: "You've learned %{count} words!",
	},
	streak: {
		countTitle: "%{count} days streak",
		reachedGoalMsg: "You achived your daily goal!",
		watchVideo: "Watch Video!",
		nextUnit: "Next Unit",
		unlockNextUnit: "Unlock Next Unit",
		lessonList: "Go to lessons list",
		redirectMsg: "Navigating to the daily goal...",
	},
	changePassword: {
		title: "Enter a new password",
		submit: "Change Password",
		old: "Old Password",
		new: "New Password",
		confirmMsg: "Please confirm your password!",
		notMatch: "The two passwords that you entered do not match!",
		confirm: "Confirm Password",
		pageTitle: "Change Password",
	},
	helpCenter: {
		pageTitle: "Help Center",
		title: "Contact our customer service",
		whatsAppSupport: "WhatsApp Support",
		telegramSupport: "Telegram Support",
		instagramSupport: "Instagram Support",
		emailSupport: "Email Support",
		supportMsg: "Please use the links above for support.",
		defMessage: "Hello, I need help with...",
		emailSubject: "Support",
		signupProblem:
			"Hello, I have a problem with completing the registration. My user ID is %{userId}",
	},
	reportBug: {
		pageTitle: "Report Bug",
		title: "Report bug to customer service",
		whatsAppSupport: "Report in WhatsApp",
		telegramSupport: "Report in Telegram",
		instagramSupport: "Report in Instagram",
		emailSupport: "Report by Email",
		supportMsg: "Please use the link above to report a bug",
		defMessage: "Hello, I would like to report a bug...",
		emailSubject: "Report Bug",
	},
	sendFeedback: {
		pageTitle: "Send Feedback",
		title: "Send Feedback to customer service",
		whatsAppSupport: "Send Feedback in WhatsApp",
		telegramSupport: "Send Feedback in Telegram",
		instagramSupport: "Send Feedback in Instagram",
		emailSupport: "Send Feedback by Email",
		supportMsg: "Please use the link above to send feedback",
		defMessage: "Hello, I would like to send feedback...",
		emailSubject: "Sending Feedback",
	},
	termsOfService: {
		pageTitle: "Terms of Service",
	},
	privacyPolicy: {
		pageTitle: "Privacy Policy",
	},
	notification: {
		error: "Error!",
	},
	userPremiumPage: {
		pageTitle: "Premium Only!",
		title: "Premium Only!",
		accessMsg: "Just Premium Users have access to this page",
		backToHome: "Back to Home",
	},
	premiumBanner: {
		title: "Start MetaOra Plus",
		subtitle: "Unlimited Access to Content",
		btn: "Get",
	},
	accessPage: {
		title: "Access Problem!",
		message: "You don't have permission to access this page",
	},
	videoDownloader: {
		error: "Downloading some videos has been failed!",
		removeSheetTitle: "Delete the downloaded video?",
		removeSheetMessage:
			"Are you sure to delete this video? you don't have access to video in offline mode and is slow connection would load slowly.",
	},
	vocabList: {
		pageTitle: "Vocabulary List",
		knew: "Knew",
		detailsSheetTitle: "Vocabulary Details",
	},
	incompleteRegister: {
		pageTitle: "Incomplete Registration",
		title: "Your register is incomplete",
		subtitle: "Sorry something went wrong for verifying your account",
		yourPH: "Your phone Number",
		yourEmail: "Your Email",
		wrongNumber: "Are you sure this phone number is correct?",
		wrongEmail: "Are you sure this email is correct?",
		sendVerifCode: "Send Verify Code",
		supportMsg:
			"If you try this and fail again up to 3 times, please contact our Support Team!",
		goToSupport: "Go to support page",
		reRegister: "Create new account",
		btnInfo:
			"You have one incomplete registration. click here to complete it.",
	},
	studypoint: {
		updateError:
			"Sorry we coudn't save your progress please try to save this part again",
	},
	notifications: {
		pageTitle: "Notifications",
		groups: {
			Review: "Review",
			Streak: "Streak",
			Quiz: "Quiz",
			Discount: "Discount",
			Speaking: "Speaking",
			Listening: "Listening",
			Grammar: "Grammar",
			Vocabulary: "Vocabulary",
			Reading: "Reading",
			AppUpdate: "App Update",
			Affiliate: "Affiliate",
			Subscription: "Subscription",
			NewContent: "New Content",
			StudyPlan: "Study Plan",
			Poll: "Poll",
			Admin: "Admin",
			AppHelpCenter: "Help Center",
			NewsIssues: "News & Issues",
			OnlineClass: "Online Class",
			SocialNetworks: "Social Networks",
			Others: "Others",
		},
	},

	chatTemplates: {
		loading: "Loading",
		text: "Text",
		"text-image": "Text with Image",
		"text-video": "Text with Video",
		image: "Image",
		video: "Video",
		"images-multiple-choice": "Images Multiple Choice",
		"image-response": "Image Response",
		"text-editor": "Text Editor",
		blank: "Blank",
		"sentence-formation": "Sentence Formation",
		"for-example": "For Example",
		"correct-response": "Correct Response",
		"incorrect-response": "Incorrect Response",
		quote: "Quote",
		authentication: "Authentication",
		"invite-friend": "Invite Friend",
		rate: "Rate",
		avatar: "Avatar",
		"radio-option": "Radio Option",
		"black-text-option": "Black Text Option",
		"picker-option": "Picker Option",
	},
	chatMessageActions: {
		textToSpeech: "Text to Speech",
		translation: "Translation",
		slowSpeech: "Slow Speech",
		assessment: "Assessment",
	},
	chatMessageUserTypes: {
		robot: "Robot",
		user: "User",
		teacher: "Teacher",
		admin: "Admin",
		marketing: "Marketing",
	},
	chat: {
		typing: "Typing",
	},
	textToSpeechSettings: {
		pageTitle: "Text to Speech Settings",
		selectVoice: "Select A voice",
	},
};

export default en;
export type Translations = typeof en;
